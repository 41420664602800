import React from "react";
import NewsArticleLayout from "../../../components/updated-article/NewsArticleLayout.component";

const isNew = true;
const href = "/ratesheet/20230316-Rate-Sheet.pdf";
const date = "16.03.2023";

const Article = () => (
  <NewsArticleLayout href={href} date={date} isNew={isNew}>
    <h1 className="page-title">Changes to our mortgage products</h1>

    <br />
    <p>With effect from Thursday 16 March 2023 we will be making the following changes to our range:</p>

    <p>
      <strong>Summary of changes</strong>
    </p>
    <p><strong>UK Buy to Let Existing Customer Switching Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60% and 75% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60% and 75% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have reduced</li>
    </ul>

    <p><strong>UK Buy to Let Existing Customer Borrowing More Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60% and 75% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60% and 75% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have reduced</li>
    </ul>

    <p><strong>UK Buy to Let Purchase Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 65% and 75% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have reduced</li>
    </ul>

    <p><strong>UK Buy to Let Remortgage Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60% and 75% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60% and 75% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have reduced</li>
    </ul>

    <p>There are no changes to any other interest rates at this time.</p>
    <p>An updated mortgage rate sheet will be published on Thursday 16 March to reflect these changes.</p>
  </NewsArticleLayout>
);

export default Article;
